import * as React from "react";
import { SVGProps } from "react";

export const ArrowIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.04297 5.75664L1.1781 10.5566L0.0429687 9.43664L3.7727 5.75664L0.0429687 2.07664L1.1781 0.95664L6.04297 5.75664Z"
      fill="#F6F9F9"
    />
  </svg>
);

export const ArrowDownIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="9"
    height="6"
    viewBox="0 0 9 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.63672 4.82785L1.13672 1.25684L4.63672 4.82951L8.13672 1.25684L4.63672 4.82785Z"
      stroke="#F6F9F9"
      strokeWidth="1.2"
    />
  </svg>
);
