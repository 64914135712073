import React from "react";
import { motion, SVGMotionProps } from "framer-motion";

interface Props extends SVGMotionProps<any> {
  isOpen?: boolean;
  width?: number;
  height?: number;
}

const MenuIcon = ({
  isOpen = false,
  width = 24,
  height = 24,
  ...props
}: Props) => {
  const variant = isOpen ? "opened" : "closed";
  const unitHeight = 4;
  const unitWidth = (unitHeight * (width as number)) / (height as number);
  const top = {
    closed: {
      x1: unitWidth / 2,
      y1: 0,
    },
    opened: {
      x1: 0,
      y1: unitHeight,
    },
  };
  const center = {
    closed: {
      x1: 0,
      x2: unitWidth,
    },
    opened: {
      x1: unitWidth / 2,
      x2: unitWidth / 2,
    },
  };
  const bottom = {
    closed: {
      y1: unitHeight,
    },
    opened: {
      y1: 0,
    },
  };
  const lineProps = {
    stroke: "#004047",
    strokeWidth: 1.2,
    vectorEffect: "non-scaling-stroke",
    initial: "closed",
    animate: variant,
    transition: { duration: 0.5 },
  };

  return (
    <motion.svg
      viewBox={`0 0 ${unitWidth} ${unitHeight}`}
      overflow="visible"
      preserveAspectRatio="none"
      width={width}
      height={height}
      {...props}
    >
      <motion.line
        x1={unitWidth / 2}
        x2={unitWidth}
        y1="0"
        y2="0"
        variants={top}
        {...lineProps}
      />
      <motion.line
        x1="0"
        x2={unitWidth}
        y1="2"
        y2="2"
        variants={center}
        {...lineProps}
      />
      <motion.line
        x1="0"
        x2={unitWidth}
        y1="4"
        y2="4"
        variants={bottom}
        {...lineProps}
      />
    </motion.svg>
  );
};

export default MenuIcon;
