import React, {
  AnchorHTMLAttributes,
  FunctionComponent,
  HTMLAttributes,
  Suspense,
  useEffect,
  useState,
} from "react";
import { Config } from "../../Config";
import { ariaNewTab } from "../../utils/ariaNewTab";
import Grid from "../Grid";
import { FooterVariant } from "./FooterVariant";
import SocialLink from "./SocialLink";
import clsx from "clsx";
import { NavigationUrls } from "../../types/NavigationUrls";
import LogoLightMint from "../../assets/logo_light_mint.webp";
import LogoUkasWhite from "../../assets/ukas_white.webp";
import LogoCqcWhite from "../../assets/cqc_white.webp";
import LogoDarkRed from "../../assets/logo_dark_red.webp";
import LogoUkasBlack from "../../assets/ukas_black.webp";
import LogoCqcBlack from "../../assets/cqc_black.webp";
import LogoIsoBlack from "../../assets/iso_black.webp";
import LogoIsoWhite from "../../assets/iso_white.webp";
import FooterBadge from "./FooterBadge";
const ParticlesBackground = React.lazy(
  () => import("../ParticlesBackground/ParticlesBackground")
);
const getLogo = (variant: FooterVariant) => {
  if (variant === "dark") return LogoLightMint;
  if (variant === "light") return LogoDarkRed;
};

const getCqc = (variant: FooterVariant) => {
  if (variant === "dark") return LogoCqcWhite;
  if (variant === "light") return LogoCqcBlack;
};

const getUkas = (variant: FooterVariant) => {
  if (variant === "dark") return LogoUkasWhite;
  if (variant === "light") return LogoUkasBlack;
};

const getIso = (variant: FooterVariant) => {
  if (variant === "dark") return LogoIsoWhite;
  if (variant === "light") return LogoIsoBlack;
};

export type FooterProps = {
  variant: FooterVariant;
  urls?: NavigationUrls;
} & HTMLAttributes<HTMLElement>;

const Footer: FunctionComponent<FooterProps> = ({
  variant,
  urls,
  ...props
}) => {
  const [particlesBg, setParticlesBg] = useState(false);

  useEffect(() => {
    if (document) {
      setParticlesBg(true);
    }
  }, []);
  return (
    <>
      <footer
        className={clsx(
          "lc-w-full lc-flex lc-flex-col lc-relative lc-font-sans lc-lead-small",
          variant === "dark" && "lc-text-main-light-second",
          variant === "light" && "lc-text-main-dark"
        )}
        {...props}
      >
        <div
          className={clsx(
            "lc-z-[0] lc-absolute lc-left-0 lc-right-0 lc-bottom-0 lc-w-full lc-h-full lc-overflow-hidden",
            variant === "light" && "lc-bg-main-warm",
            variant === "dark" && "lc-bg-main-dark"
          )}
        >
          {particlesBg && (
            <Suspense fallback={null}>
              <ParticlesBackground variant={variant} />
            </Suspense>
          )}
        </div>
        <Grid>
          <div
            className={clsx(
              "lc-z-[1] lc-col-span-full lc-w-full lc-flex lc-py-14 lc-justify-between lc-flex-col lc-items-center",
              "md:lc-items-start",
              "lg:lc-flex-row"
            )}
          >
            <div
              className={clsx(
                "lc-flex lc-w-fit lc-flex-col lc-gap-4",
                "md:lc-gap-32 md:lc-flex-row",
                "lg:lc-justify-start lg:lc-gap-6 lg:lc-flex-col",
                "2xl:lc-gap-8"
              )}
            >
              <div
                className={clsx(
                  "lc-flex lc-flex-col lc-items-center",
                  "md:lc-items-start"
                )}
              >
                <img
                  alt="Medical Laboratories logo"
                  className="lc-h-16 lc-w-[8.1rem] lc-object-contain"
                  src={getLogo(variant)}
                />

                <div
                  className={clsx(
                    "lc-flex lc-flex-col lc-mt-2 lc-items-center lc-justify-center lc-body-xs lc-text-center",
                    "md:lc-items-start md:lc-body-xs md:lc-text-left",
                    "2xl:lc-body-s",
                    variant === "light" && "lc-text-main-dark",
                    variant === "dark" && "lc-text-main-light-second"
                  )}
                >
                  <span>Med Logistics and Deliveries Limited,</span>
                  <span>Registered in England: No. 14515991</span>

                  <a
                    className={clsx(
                      "lc-mt-4 md:lc-mt-0 lc-underline lc-tap-none lc-break-all",
                      "hover:lc-scale-105 lc-transition-all lc-default-focus",
                      variant === "light" &&
                        "lc-text-red-500 lc-default-focus-red-500",
                      variant === "dark" &&
                        "lc-text-mint-400 lc-default-focus-mint-400"
                    )}
                    href={`mailto:${Config.MAIL}`}
                  >
                    {Config.MAIL}
                  </a>
                </div>
              </div>
              <div
                className={clsx(
                  "lc-flex lc-gap-4 lc-items-end lc-justify-center lc-mt-4 lc-flex-wrap",
                  "md:lc-justify-start md:lc-mt-0"
                )}
              >
                {/*<FooterBadge
                  type="ukas"
                  variant={variant}
                  href={Config.UKAS_URL}
                  imgClassName="lc-h-14 lc-w-[2.75rem] 2xl:lc-h-16 2xl:lc-w-[3.1437rem]"
                /> */}

                <FooterBadge
                  type="cqc"
                  variant={variant}
                  href={Config.CQC_URL}
                  imgClassName="lc-h-14 lc-w-[4.375rem] 2xl:lc-h-16 2xl:lc-w-20"
                />

                <FooterBadge
                  type="iso"
                  variant={variant}
                  href={urls?.iso}
                  imgClassName="lc-h-14 lc-w-14 2xl:lc-h-16 2xl:lc-w-16"
                />
              </div>
            </div>

            <div
              className={clsx(
                "lc-flex lc-flex-col lc-mt-12 lc-gap-8",
                "md:lc-flex-row md:lc-gap-20",
                "lg:lc-mt-0 lg:lc-justify-start lg:lc-gap-12",
                "xl:lc-gap-20"
              )}
            >
              <UlContainer>
                <ItemsHeader variant={variant}>SERVICES</ItemsHeader>
                <ItemsUl>
                  <li>
                    <ItemLink
                      variant={variant}
                      href={
                        urls?.medicalCouriers
                          ? urls?.medicalCouriers
                          : Config.MEDICAL_COURIERS
                      }
                    >
                      Medical Couriers
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink
                      variant={variant}
                      href={
                        urls?.medicalServices
                          ? urls?.medicalServices
                          : Config.MEDICAL_SERVICES
                      }
                    >
                      Medical Services
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink
                      variant={variant}
                      href={Config.LABORATORIES_URL}
                      target="_blank"
                      aria-label={ariaNewTab("In-Clinic Serivces")}
                    >
                      In-Clinic Services
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink
                      variant={variant}
                      href={Config.INNOVATION_HUB_URL}
                      target="_blank"
                      aria-label={ariaNewTab("Innovation Hub")}
                    >
                      Innovation Hub
                    </ItemLink>
                  </li>
                </ItemsUl>
              </UlContainer>
              <UlContainer>
                <ItemsHeader variant={variant}>COMPANY</ItemsHeader>
                <ItemsUl>
                  <li>
                    <ItemLink
                      variant={variant}
                      href={urls?.home ? urls?.home : Config.HOME}
                    >
                      Home
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink
                      variant={variant}
                      href={
                        urls?.contactUs ? urls?.contactUs : Config.CONTACT_US
                      }
                    >
                      Contact Us
                    </ItemLink>
                  </li>

                  <li>
                    <ItemLink
                      variant={variant}
                      href={urls?.aboutUs ? urls?.aboutUs : Config.ABOUT_US}
                    >
                      About Us
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink
                      variant={variant}
                      href={
                        urls?.aboutUs
                          ? urls?.pressAboutUs
                          : Config.PRESS_ABOUT_US
                      }
                    >
                      Press About Us
                    </ItemLink>
                  </li>
                </ItemsUl>
              </UlContainer>
              <UlContainer>
                <ItemsHeader variant={variant}>DOCUMENTS</ItemsHeader>
                <ItemsUl>
                  <li>
                    <ItemLink
                      variant={variant}
                      href={urls?.privacy ? urls?.privacy : Config.PRIVACY}
                    >
                      Privacy & Cookies Policy
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink
                      variant={variant}
                      href={urls?.terms ? urls?.terms : Config.TERMS}
                    >
                      Terms of Service
                    </ItemLink>
                  </li>
                  <li>
                    <ItemLink
                      variant={variant}
                      href={
                        urls?.modernSlavery
                          ? urls?.modernSlavery
                          : Config.MODERN_SLAVERY
                      }
                    >
                      Modern Slavery Policy
                    </ItemLink>
                  </li>
                </ItemsUl>
              </UlContainer>
            </div>
          </div>
        </Grid>
        <div
          className={clsx(
            "lc-border-t lc-border-solid lc-z-[1] lc-border-b-transparent lc-border-x-transparent",
            variant === "dark" && "lc-border-main-lines-on-dark",
            variant === "light" && "lc-border-main-lines-warm"
          )}
        >
          <Grid>
            <div
              className={clsx(
                "lc-col-span-full lc-w-full lc-flex lc-py-6 lc-justify-between lc-items-center lc-gap-4 lc-flex-col-reverse lc-body-xs",
                "md:lc-flex-row md:lc-body-small",
                "2xl:lc-body-xs",
                variant === "light" && "lc-text-main-dark",
                variant === "dark" && "lc-text-main-light-second"
              )}
            >
              <div
                className={clsx(
                  "lc-flex lc-items-center lc-gap-4 lc-flex-col lc-text-center ",
                  "sm:lc-gap-3",
                  "md:lc-flex-row"
                )}
              >
                <span>&copy;2023 by Medical Logistcs Group</span>
                <div
                  className={clsx(
                    "lc-w-px lc-h-3 lc-hidden",
                    "md:lc-block",
                    variant === "light" && "lc-bg-main-dark",
                    variant === "dark" && "lc-bg-main-light-second"
                  )}
                />
                <span>
                  Created by{" "}
                  <a
                    className={clsx(
                      "hover:lc-scale-105 lc-transition-all lc-default-focus lc-underline lc-tap-none",
                      "lc-inline-block lc-origin-left lc-text-inherit",
                      variant === "light" &&
                        "hover:lc-text-red-500 lc-default-focus-red-500",
                      variant === "dark" &&
                        "hover:lc-text-mint-400 lc-default-focus-mint-500"
                    )}
                    href={Config.ITGENERATOR_URL}
                    target="_blank"
                    aria-label={ariaNewTab("ITgenerator")}
                  >
                    ITgenerator
                  </a>
                </span>
              </div>
              <div className="lc-flex lc-gap-4 lc-items-center">
                <span
                  className={clsx(
                    "lc-hidden lc-leading-none",
                    "md:lc-inline-block"
                  )}
                >
                  Follow us:
                </span>
                <SocialLink variant={variant} type="LinkedIn" />
                <SocialLink variant={variant} type="YouTube" />
              </div>
            </div>
          </Grid>
        </div>
      </footer>
    </>
  );
};

export default Footer;

const UlContainer: FunctionComponent<HTMLAttributes<HTMLElement>> = ({
  children,
}) => (
  <div
    className={clsx(
      "lc-flex lc-flex-col lc-items-center",
      "md:lc-gap-5 md:lc-items-start"
    )}
  >
    {children}
  </div>
);

const ItemsHeader: FunctionComponent<
  HTMLAttributes<HTMLElement> & { variant: FooterVariant }
> = ({ children, variant }) => (
  <span
    className={clsx(
      "lc-mb-5 lc-title-buttons",
      "md:lc-title-lead-xs md:lc-mb-0",
      "2xl:lc-title-buttons",
      variant === "light" && "lc-text-main-dark",
      variant === "dark" && "lc-text-main-light-second"
    )}
  >
    {children}
  </span>
);

const ItemsUl: FunctionComponent<HTMLAttributes<HTMLElement>> = ({
  children,
}) => (
  <ul
    className={clsx(
      "lc-flex lc-m-0 lc-flex-col lc-gap-4 lc-p-0 lc-list-none lc-items-center",
      "md:lc-items-start"
    )}
  >
    {children}
  </ul>
);

const ItemLink: FunctionComponent<
  AnchorHTMLAttributes<HTMLAnchorElement> & { variant: FooterVariant }
> = ({ children, variant, ...props }) => (
  <a
    className={clsx(
      "hover:lc-scale-105 lc-transition-all lc-default-focus lc-tap-none",
      "lc-block lc-no-underline lc-text-center lc-body-s md:lc-body-xs 2xl:lc-body-s",
      variant === "dark" &&
        "lc-text-main-light-second lc-default-focus-mint-500",
      variant === "light" && "lc-text-main-text-second lc-default-focus-red-500"
    )}
    {...props}
  >
    {children}
  </a>
);
