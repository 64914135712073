import clsx from "clsx";
import React, { AnchorHTMLAttributes, FunctionComponent } from "react";
import { NavbarVariant } from "./NavbarVariant";

type NavbarOptionLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  variant: NavbarVariant;
  active?: boolean;
};

const NavbarOptionLink: FunctionComponent<NavbarOptionLinkProps> = ({
  variant,
  className,
  children,
  active,
  ...props
}) => (
  <a
    {...props}
    className={clsx(
      className,
      "lc-text-inherit lc-no-underline",
      active && "lc-body-xs-bold 2xl:lc-body-s-bold",
      variant === "dark" && "hover:lc-bg-main-dark-second",
      variant === "light" && "hover:lc-bg-main-lines-warm",
      variant === "mint" && "hover:lc-bg-white/30",
      "lc-tap-none",
      "lc-group lc-flex lc-flex-col lc-w-full lc-box-border lc-outline-none lc-transition-colors lc-duration-300"
    )}
  >
    <span
      className={clsx(
        variant === "light" && "group-focus-visible:lc-border-red-500",
        variant === "dark" && "group-focus-visible:lc-border-mint-500",
        variant === "mint" && "group-focus-visible:lc-border-mint-500",
        "lc-border-transparent lc-rounded lc-border lc-border-solid",
        "lc-duration-300 lc-mx-5 lc-my-3 lc-transition-colors lc-p-1 "
      )}
    >
      {children}
    </span>
    <div
      className={clsx(
        variant === "dark" &&
          "lc-bg-gradient-to-r lc-from-mint-500 lc-to-red-500",
        variant === "light" && "lc-bg-red-500",
        variant === "mint" && "lc-bg-mint-500",
        "lc-scale-0 lc-w-full lc-h-px lc-transition-transform lc-duration-300",
        "group-hover:lc-scale-100"
      )}
    />
  </a>
);

export default NavbarOptionLink;
