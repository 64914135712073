import * as React from "react";
import { SVGProps } from "react";

export const LinkedInIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      className="lc-transition-colors lc-duration-300"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12C0 5.373 5.373 0 12 0s12 5.373 12 12-5.373 12-12 12S0 18.627 0 12Zm6.15-2.025V18H8.7V9.975H6.15ZM6 7.425c0 .825.6 1.425 1.425 1.425.825 0 1.425-.6 1.425-1.425C8.85 6.6 8.25 6 7.425 6 6.675 6 6 6.6 6 7.425ZM15.45 18h2.4v-4.95c0-2.475-1.5-3.3-2.925-3.3-1.275 0-2.175.825-2.4 1.35V9.975h-2.4V18h2.55v-4.275c0-1.125.75-1.725 1.5-1.725s1.275.375 1.275 1.65V18Z"
    />
  </svg>
);

export const YouTubeIcon = ({ ...props }: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path fill="#f6f9f9" d="m10.38,14.29v-4.2s3.65,2.1,3.65,2.1l-3.65,2.1Z" />
    <path
      fill="#f6f9f9"
      fillRule="evenodd"
      className="lc-transition-colors lc-duration-300"
      d="m12,0C5.37,0,0,5.37,0,12s5.37,12,12,12,12-5.37,12-12S18.63,0,12,0Zm0,17.09h.01c.18,0,4.4,0,5.46-.28.6-.16,1.07-.63,1.24-1.23.2-1.11.3-2.24.29-3.36.01-1.13-.08-2.26-.28-3.38-.16-.6-.63-1.07-1.23-1.23-1.08-.3-5.43-.31-5.48-.31h-.01c-.18,0-4.4,0-5.46.28-.6.17-1.07.64-1.24,1.25-.2,1.11-.3,2.24-.29,3.37,0,1.13.09,2.25.28,3.36.16.6.63,1.07,1.23,1.23,1.09.3,5.43.31,5.48.31Z"
    />
  </svg>
);
