import clsx from "clsx";
import React, {
  FunctionComponent,
  HTMLAttributeAnchorTarget,
  HTMLAttributes,
  useEffect,
  useRef,
  useState,
} from "react";
import { Config } from "../../Config";
import SocialLink from "../Footer/SocialLink";
import Grid from "../Grid";
import { ArrowDownIcon, ArrowIcon } from "./Icons";
import { AnimatePresence, motion } from "framer-motion";
import MenuIcon from "./MenuIcon";
import { NavbarVariant } from "./NavbarVariant";
import { NavbarPages } from "./NavbarPages";
import NavbarLink from "./NavbarLink";
import NavbarOptionLink from "./NavbarOptionLink";
import { NavigationUrls } from "../../types/NavigationUrls";
import LogoDarkRed from "../../assets/logo_dark_red.webp";
import LogoLightMint from "../../assets/logo_light_mint.webp";
import LogoDarkMint from "../../assets/logo_dark_mint.webp";
import NavbarSpace from "../NavbarSpace";

export type NavbarProps = {
  variant: NavbarVariant;
  selection?: NavbarPages;
  urls?: NavigationUrls;
  includeSpace?: boolean;
} & Pick<HTMLAttributes<HTMLDivElement>, "style">;

type MobileItems = {
  name: string;
  url: string;
  page: NavbarPages;
  target?: HTMLAttributeAnchorTarget;
};

const getHomeUrl = (url?: string) => {
  if (url) return url;
  else return Config.HOME;
};

const getMedicalServicesUrl = (url?: string) => {
  if (url) return url;
  else return Config.MEDICAL_SERVICES;
};
const getCouriersUrl = (url?: string) => {
  if (url) return url;
  else return Config.MEDICAL_COURIERS;
};
const getInClinicUrl = (url?: string) => {
  if (url) return url;
  else return Config.LABORATORIES_URL;
};
const getAboutUsUrl = (url?: string) => {
  if (url) return url;
  else return Config.ABOUT_US;
};

const Navbar: FunctionComponent<NavbarProps> = ({
  variant,
  urls,
  selection,
  style,
  includeSpace = false,
}) => {
  const [mobileMenuOpened, setMobileMenuOpened] = useState(false);
  const [servicesVisible, setServicesVisible] = useState(false);

  const HOME_URL = getHomeUrl(urls?.home);
  const MEDICAL_SERVICES_URL = getMedicalServicesUrl(urls?.medicalServices);
  const MEDICAL_COURIERS_URL = getCouriersUrl(urls?.medicalCouriers);
  const IN_CLINIC_URL = getInClinicUrl(urls?.inClinicServices);
  const ABOUT_US_URL = getAboutUsUrl(urls?.aboutUs);

  const ITEMS: MobileItems[] = [
    {
      name: "Home",
      url: HOME_URL,
      page: "home",
    },
    {
      name: "Medical Couriers",
      url: MEDICAL_COURIERS_URL,
      page: "couriers",
    },
    {
      name: "Medical Services",
      url: MEDICAL_SERVICES_URL,
      page: "services",
    },
    {
      name: "In-clinic Services",
      url: IN_CLINIC_URL,
      page: "in-clinic",
      target: "_blank",
    },
    { name: "About", url: ABOUT_US_URL, page: "about" },
    { name: "Blog", url: Config.BLOG, page: "blog" },
  ];

  useEffect(() => {
    const callback = (e: UIEvent) => {
      setMobileMenuOpened(false);
    };
    window.addEventListener("resize", callback);
    return () => window.removeEventListener("resize", callback);
  }, []);

  useEffect(() => {
    document.documentElement.style.overflow = mobileMenuOpened
      ? "hidden"
      : "initial";
  }, [mobileMenuOpened]);

  const scrollRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <nav
        className={clsx(
          variant === "mint" &&
            "lc-bg-mint-medical/95 lc-border-b-main-lines-mint lc-text-main-dark/90",
          variant === "light" &&
            "lc-bg-main-warm/95 lc-border-b-main-lines-warm lc-text-main-dark/90",
          variant === "dark" &&
            "lc-bg-main-dark/95 lc-border-b-main-lines-on-dark lc-text-main-light/90",
          "lc-border-0 lc-border-b lc-border-solid",
          "lc-fixed lc-top-0 lc-left-0 lc-right-0 lc-z-10",
          "lc-font-sans"
        )}
        style={style}
      >
        <Grid>
          <div className={clsx("lc-box-border lc-w-full lc-col-span-full")}>
            <div
              className={clsx(
                "lc-h-[3.75rem] lc-flex lc-items-center lc-justify-between",
                "sm:lc-h-16",
                "xl:lc-h-[4.5rem]",
                "2xl:lc-h-20"
              )}
            >
              <a
                href={HOME_URL}
                className={clsx(
                  variant === "dark" && "lc-default-focus-mint-500",
                  variant === "light" && "lc-default-focus-red-500",
                  variant === "mint" && "lc-default-focus-mint-600",
                  "lc-transition-transform lc-my-3 lc-default-focus lc-h-9 lc-tap-none",
                  "sm:lc-h-10",
                  "hover:lc-scale-105"
                )}
              >
                <img
                  alt="Medical Laboratories logo"
                  src={
                    variant === "dark"
                      ? LogoLightMint
                      : variant === "mint"
                      ? LogoDarkMint
                      : LogoDarkRed
                  }
                  className={clsx(
                    "lc-h-9 lc-w-[4.5625rem]",
                    "sm:lc-h-10 sm:lc-w-[5.0625rem]"
                  )}
                ></img>
              </a>

              {/* DESKTOP MENU */}
              <ul
                className={clsx(
                  "lc-hidden lc-h-full lc-list-none lc-p-0 lc-m-0 lc-items-center lc-body-xs",
                  "sm:lc-flex sm:lc-gap-10",
                  "md:lc-gap-16",
                  "2xl:lc-body-s"
                )}
              >
                <li
                  className="lc-relative lc-h-full lc-flex lc-items-center lc-justify-center"
                  onClick={(e) => {
                    setServicesVisible(true);
                  }}
                  onFocus={(e) => {
                    setServicesVisible(true);
                  }}
                  onBlur={(e) => {
                    setServicesVisible(
                      e.currentTarget.contains(e.relatedTarget)
                    );
                  }}
                  onMouseEnter={(e) => {
                    setServicesVisible(true);
                  }}
                  onMouseLeave={() => setServicesVisible(false)}
                >
                  <button
                    className={clsx(
                      variant === "dark" && "lc-default-focus-mint-500",
                      variant === "light" && "lc-default-focus-red-500",
                      variant === "mint" && "lc-default-focus-mint-600",
                      "lc-tap-none lc-h-fit lc-flex lc-items-center lc-default-focus lc-bg-transparent lc-border-0 lc-text-inherit lc-cursor-pointer lc-font-sans lc-body-xs",
                      "2xl:lc-body-s"
                    )}
                    onFocus={() => setServicesVisible(true)}
                  >
                    <span>Services</span>
                    <ArrowDownIcon
                      className={clsx(
                        variant === "dark" && "[&>path]:lc-stroke-main-light",
                        variant === "light" && "[&>path]:lc-stroke-main-dark",
                        variant === "mint" && "[&>path]:lc-stroke-main-dark",
                        "lc-mt-1 lc-ml-1 lc-transition-transform lc-duration-300",
                        servicesVisible ? "lc-rotate-180" : "lc-rotate-0"
                      )}
                    />
                  </button>
                  <AnimatePresence>
                    {servicesVisible && (
                      <motion.ul
                        animate={{ scaleY: 1, opacity: 1 }}
                        exit={{ scaleY: 0, opacity: 0 }}
                        initial={{ scaleY: 0, opacity: 0 }}
                        className={clsx(
                          "lc-top-[3.75rem]",
                          "sm:lc-top-16",
                          "xl:lc-top-[4.5rem]",
                          "2xl:lc-top-20",
                          variant === "dark" &&
                            "lc-bg-main-dark lc-border-main-lines-on-dark",
                          variant === "light" &&
                            "lc-bg-main-warm lc-border-main-lines-warm",
                          variant === "mint" &&
                            "lc-bg-mint-medical lc-border-main-lines-mint",
                          "lc-border lc-border-solid",
                          "lc-list-none lc-p-0 lc-flex lc-flex-col lc-absolute -lc-left-6 lc-whitespace-nowrap lc-origin-top"
                        )}
                      >
                        <li>
                          <NavbarOptionLink
                            variant={variant}
                            href={MEDICAL_COURIERS_URL}
                            active={selection === "couriers"}
                          >
                            Medical Couriers
                          </NavbarOptionLink>
                        </li>
                        <li>
                          <NavbarOptionLink
                            variant={variant}
                            href={MEDICAL_SERVICES_URL}
                            active={selection === "services"}
                          >
                            Medical Services
                          </NavbarOptionLink>
                        </li>

                        <li>
                          <NavbarOptionLink
                            target={"_blank"}
                            variant={variant}
                            href={IN_CLINIC_URL}
                            active={selection === "in-clinic"}
                          >
                            In-clinic Services
                          </NavbarOptionLink>
                        </li>
                      </motion.ul>
                    )}
                  </AnimatePresence>
                </li>
                <li>
                  <NavbarLink
                    active={selection === "about"}
                    href={ABOUT_US_URL}
                    variant={variant}
                  >
                    <span>About</span>
                  </NavbarLink>
                </li>
                <li>
                  <NavbarLink
                    active={selection === "blog"}
                    href={Config.BLOG}
                    variant={variant}
                  >
                    Blog
                  </NavbarLink>
                </li>
              </ul>

              {/* MOBILE MENU: */}
              <div className="sm:lc-hidden">
                <button
                  onClick={() => setMobileMenuOpened((p) => !p)}
                  className={clsx(
                    "lc-tap-none lc-body-s scheme lc-p-0",
                    "lc-gap-1 lc-flex lc-items-center",
                    "lc-bg-transparent lc-border-0 lc-text-inherit lc-font-sans lc-cursor-pointer lc-default-focus lc-default-focus-mint-500"
                  )}
                >
                  <MenuIcon
                    width={10}
                    height={10}
                    isOpen={mobileMenuOpened}
                    className={clsx(
                      variant === "dark" && "[&>*]:lc-stroke-white",
                      variant === "light" && "[&>*]:lc-stroke-main-dark",
                      variant === "mint" && "[&>*]:lc-stroke-main-dark"
                    )}
                  />
                  {mobileMenuOpened ? "Close" : "Menu"}
                </button>

                <AnimatePresence>
                  {mobileMenuOpened && (
                    <motion.div
                      ref={scrollRef}
                      onAnimationStart={() => {
                        scrollRef.current!.style.overflowY = "hidden";
                      }}
                      onAnimationComplete={() => {
                        scrollRef.current!.style.overflowY = "auto";
                      }}
                      layout="size"
                      transition={{ bounce: false, duration: 0.3 }}
                      initial={{ height: 0 }}
                      animate={{ height: "auto" }}
                      exit={{
                        height: 0,
                        transition: {
                          delay: 0.5,
                          duration: 0.3,
                        },
                      }}
                      className={clsx(
                        "lc-bg-[length:100%_auto] lc-bg-no-repeat lc-bg-bottom lc-overflow-x-hidden",
                        variant === "light" &&
                          "lc-bg-[url('/images/bg-mobile-light.webp')]",
                        variant === "mint" &&
                          "lc-bg-[url('/images/bg-mobile-mint.webp')]",
                        variant === "dark" &&
                          "lc-bg-[url('/images/bg-mobile-dark.webp')]",
                        variant === "dark" && "lc-bg-main-dark",
                        variant === "light" && "lc-bg-main-warm",
                        variant === "mint" && "lc-bg-mint-medical",
                        "lc-z-50 lc-fixed lc-left-0 lc-w-full lc-bottom-0 lc-flex lc-origin-top",
                        "lc-top-[61px]",
                        "sm:lc-top-[65px]",
                        "xl:lc-top-[73px]",
                        "2xl:lc-top-[81px]",
                        "sm:lc-hidden"
                      )}
                    >
                      <Grid>
                        <div
                          className={clsx(
                            "lc-flex lc-flex-col lc-justify-between lc-col-span-full lc-mt-4 lc-h-[calc(100%-2rem)]"
                          )}
                        >
                          <ul
                            className={clsx(
                              "lc-list-none lc-p-0 lc-flex lc-flex-col lc-gap-2"
                            )}
                          >
                            {ITEMS.map((item, index) => (
                              <motion.li
                                initial={{ translateX: "25%", opacity: 0 }}
                                exit={{
                                  translateX: "25%",
                                  opacity: 0,
                                  transition: {
                                    delay: index * 0.05,
                                    bounce: false,
                                  },
                                }}
                                animate={{
                                  opacity: 1,
                                  translateX: "0%",
                                  transition: {
                                    delay: 0.25 + index * 0.05,
                                    bounce: false,
                                  },
                                }}
                                key={`menu-${item.name}`}
                                className="lc-flex lc-items-center lc-title-s"
                              >
                                <ArrowIcon
                                  className={clsx(
                                    variant === "light" &&
                                      "[&>path]:lc-fill-main-dark",
                                    variant === "dark" &&
                                      "[&>path]:lc-fill-main-warm",
                                    variant === "mint" &&
                                      "[&>path]:lc-fill-main-dark"
                                  )}
                                />
                                <a
                                  target={item.target}
                                  className={clsx(
                                    selection === item.page
                                      ? variant === "light"
                                        ? "lc-text-red-500"
                                        : "lc-text-mint-500"
                                      : "",
                                    variant === "dark" &&
                                      "lc-default-focus-mint-500",
                                    variant === "mint" &&
                                      "lc-default-focus-mint-500",
                                    variant === "light" &&
                                      "lc-default-focus-red-500",
                                    "lc-tap-none lc-p-2 lc-no-underline lc-text-inherit lc-default-focus lc-transition-all hover:lc-scale-105 lc-origin-left"
                                  )}
                                  href={item.url}
                                >
                                  {item.name}
                                </a>
                              </motion.li>
                            ))}
                          </ul>
                          <motion.div
                            initial={{ opacity: 0 }}
                            exit={{ opacity: 0 }}
                            animate={{
                              opacity: 1,
                              transition: {
                                // 0.05 delay between animations
                                // 0.5 - 0.25 animation time + 0.25 first animation delay
                                delay: ITEMS.length * 0.05 + 0.5,
                              },
                            }}
                            className="lc-w-full lc-flex lc-flex-col lc-gap-6"
                          >
                            <a
                              className={clsx(
                                "lc-mt-8 lc-underline lc-tap-none",
                                "lc-default-focus lc-body-m lc-transition-all hover:lc-scale-105",
                                variant === "dark" &&
                                  "lc-text-mint-500 lc-default-focus-mint-500",
                                variant === "mint" &&
                                  "lc-text-mint-500 lc-default-focus-mint-500",
                                variant === "light" &&
                                  "lc-text-red-500 lc-default-focus-red-500"
                              )}
                              href={`mailto:${Config.MAIL}`}
                            >
                              {Config.MAIL}
                            </a>
                            <div className="lc-flex lc-gap-6">
                              <SocialLink type="LinkedIn" variant={variant} />
                              <SocialLink type="YouTube" variant={variant} />
                            </div>
                          </motion.div>
                        </div>
                      </Grid>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </Grid>
      </nav>
      {includeSpace && <NavbarSpace variant={variant} />}
    </>
  );
};

export default Navbar;
