import React, { AnchorHTMLAttributes, FunctionComponent } from "react";
import clsx from "clsx";
import { NavbarVariant } from "./NavbarVariant";

type NavbarLinkProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  variant: NavbarVariant;
  active?: boolean;
};

const NavbarLink: FunctionComponent<NavbarLinkProps> = ({
  variant,
  active,
  children,
  className,
  ...props
}) => (
  <a
    className={clsx(
      className,
      "lc-group lc-text-inherit lc-no-underline lc-tap-none",
      active && "after:lc-scale-100 lc-body-xs-bold 2xl:lc-body-s-bold",
      variant === "dark" &&
        "after:lc-bg-gradient-to-r after:lc-from-mint-500  after:lc-to-red-500 lc-default-focus-mint-500",
      variant === "light" && "after:lc-bg-red-500 lc-default-focus-red-500",
      variant === "mint" && "after:lc-bg-mint-500 lc-default-focus-mint-500",
      "hover:after:lc-scale-100  after:lc-duration-300",
      "after:lc-transition-transform after:lc-scale-0 after:lc-content-[''] after:lc-absolute after:lc-h-px after:-lc-left-2 after:-lc-right-2 after:-lc-bottom-[21px]",
      "lc-default-focus lc-transition-all",
      "after:-lc-bottom-[1.3125rem]",
      "sm:after:-lc-bottom-[1.4375rem]",
      "xl:after:-lc-bottom-[1.6875rem]",
      "2xl:after:-lc-bottom-[1.875rem]"
    )}
    {...props}
  >
    <span
      className={clsx(
        "lc-absolute lc-left-0 lc-right-0 lc-text-center",
        "lc-body-xs 2xl:lc-body-s",
        "group-hover:lc-invisible",
        active && "lc-invisible"
      )}
    >
      {children}
    </span>
    <span
      className={clsx(
        "group-hover:lc-visible",
        active ? "lc-visible" : "lc-invisible",
        "lc-body-xs-bold 2xl:lc-body-s-bold"
      )}
    >
      {children}
    </span>
  </a>
);

export default NavbarLink;
