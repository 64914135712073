import clsx from "clsx";
import React, { FunctionComponent } from "react";

type LinesProps = {
  variant: "light" | "dark" | "mint";
};

const Lines: FunctionComponent<LinesProps> = ({ variant }) => {
  return (
    <>
      <div
        className={clsx(
          variant === "dark" && "lc-bg-main-lines-on-dark",
          variant === "light" && "lc-bg-main-lines-warm",
          variant === "mint" && "lc-bg-main-lines-mint",
          "lc-w-px lc-fixed lc-top-0 lc-bottom-0 lc-left-4",
          "sm:lc-left-8",
          "md:lc-left-10",
          "lg:lc-left-[3.125rem]",
          "xl:lc-left-[calc((50%-0.5px-540px)/2)]",
          "2xl:lc-left-[calc((50%-0.5px-588px)/2)]"
        )}
      />
      <div
        className={clsx(
          variant === "dark" && "lc-bg-main-lines-on-dark",
          variant === "light" && "lc-bg-main-lines-warm",
          variant === "mint" && "lc-bg-main-lines-mint",
          "lc-w-px lc-fixed lc-top-0 lc-bottom-0 lc-right-4",
          "sm:lc-right-8",
          "md:lc-right-10",
          "lg:lc-right-[3.125rem]",
          "xl:lc-right-[calc((50%-0.5px-540px)/2)]",
          "2xl:lc-right-[calc((50%-0.5px-588px)/2)]"
        )}
      />
    </>
  );
};

export default Lines;
