import clsx from "clsx";
import React, { AnchorHTMLAttributes, FunctionComponent } from "react";
import { FooterVariant } from "./FooterVariant";
import LogoUkasWhite from "../../assets/ukas_white.webp";
import LogoCqcWhite from "../../assets/cqc_white.webp";
import LogoUkasBlack from "../../assets/ukas_black.webp";
import LogoCqcBlack from "../../assets/cqc_black.webp";
import LogoIsoBlack from "../../assets/iso_black.webp";
import LogoIsoWhite from "../../assets/iso_white.webp";
import { ariaNewTab } from "../../utils/ariaNewTab";

type FooterBadgeType = "cqc" | "ukas" | "iso";

type BadgeLinkType = { [VARIANT in FooterVariant]: string };

type FooterBadgeProps = {
  variant: FooterVariant;
  type: FooterBadgeType;
  href?: string;
  imgClassName?: string;
};

const BadgeTypeData: {
  [TYPE in FooterBadgeType]: BadgeLinkType;
} = {
  cqc: {
    dark: LogoCqcWhite,
    light: LogoCqcBlack,
  },
  iso: {
    dark: LogoIsoWhite,
    light: LogoIsoBlack,
  },
  ukas: {
    dark: LogoUkasWhite,
    light: LogoUkasBlack,
  },
};

const FooterBadge: FunctionComponent<FooterBadgeProps> = ({
  variant,
  href,
  type,
  imgClassName,
}) => {
  const alt = `${type.toUpperCase()} logo`;
  return (
    <a
      className={clsx(
        "lc-leading-none lc-h-fit lc-default-focus lc-transition-all lc-tap-none",
        "hover:lc-scale-105",
        variant === "dark" && "lc-default-focus-mint-500",
        variant === "light" && "lc-default-focus-red-500"
      )}
      href={href}
      target="_blank"
      aria-label={ariaNewTab(alt)}
    >
      <img
        alt={alt}
        src={BadgeTypeData[type][variant]}
        className={imgClassName}
      />
    </a>
  );
};

export default FooterBadge;
