import clsx from "clsx";
import React, { FunctionComponent, HTMLAttributes } from "react";
import { Config } from "../../Config";
import { ariaNewTab } from "../../utils/ariaNewTab";
import { FooterVariant } from "./FooterVariant";
import { LinkedInIcon, YouTubeIcon } from "./Icons";

export type SocialType = "LinkedIn" | "YouTube";

export type SocialVariant = "dark" | "light" | "mint";

export type SocialLinkProps = {
  type: SocialType;
  variant: SocialVariant;
} & Omit<HTMLAttributes<HTMLAnchorElement>, "href" | "target" | "aria-label">;

export const getHref = (type: SocialType) => {
  if (type === "LinkedIn") return Config.LINKEDIN_URL;
  if (type === "YouTube") return Config.YOUTUBE_URL;
  return undefined;
};

const SocialLink: FunctionComponent<SocialLinkProps> = ({
  type,
  variant,
  ...props
}) => {
  return (
    <a
      href={getHref(type)}
      target="_blank"
      className={clsx(
        "lc-w-8 lc-h-8 lc-transition-all lc-default-focus lc-default-focus-rounded lc-tap-none",
        "hover:lc-scale-105",
        "md:lc-w-6 md:lc-h-6",
        variant === "dark" &&
          "lc-default-focus-mint-500 [&>svg>path]:lc-fill-main-light [&>svg>path]:hover:lc-fill-mint-500",
        variant === "mint" &&
          "lc-default-focus-mint-500 [&>svg>path]:lc-fill-main-dark [&>svg>path]:hover:lc-fill-mint-500",
        variant === "light" &&
          "lc-default-focus-red-500 [&>svg>path]:lc-fill-main-dark [&>svg>path]:hover:lc-fill-red-500"
      )}
      aria-label={ariaNewTab(type)}
      {...props}
    >
      {type === "LinkedIn" && <LinkedInIcon />}
      {type === "YouTube" && <YouTubeIcon />}
    </a>
  );
};

export default SocialLink;
