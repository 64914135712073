import clsx from "clsx";
import React, { FunctionComponent, HTMLAttributes } from "react";

export type GridProps = {} & HTMLAttributes<HTMLElement>;

const Grid: FunctionComponent<GridProps> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={clsx(
        className,
        "lc-box-border lc-grid lc-grid-cols-4 lc-gap-x-3 lc-mx-8 lc-items-center lc-justify-center",
        "sm:lc-gap-x-4 sm:lc-grid-cols-6 sm:lc-mx-16",
        "md:lc-mx-20",
        "lg:lc-grid-cols-8 lg:lc-mx-[6.25rem]",
        "xl:lc-grid-cols-12 xl:lc-gap-x-6 xl:lc-w-full xl:lc-px-[6.25rem] xl:lc-max-w-screen-xl xl:lc-mx-auto",
        "2xl:lc-px-[11.25rem] 2xl:lc-max-w-screen-2xl"
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default Grid;
