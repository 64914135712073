export const Config = {
  MAIL: "info@medicallogistics.co.uk",
  UKAS_URL:
    "https://www.ukas.com/wp-content/uploads/schedule_uploads/971748/22387-Medical-Multiple.pdf",
  CQC_URL: "https://www.cqc.org.uk/provider/1-10972391656/registration-info/",
  ITGENERATOR_URL: "https://www.itgenerator.com/",
  INNOVATION_HUB_URL: "https://www.medicallogistics.tech/",
  LINKEDIN_URL: "https://www.linkedin.com/company/medical-logistics/",
  YOUTUBE_URL: "https://www.youtube.com/channel/UCvlZ5H6qVArSPVvpWBhi7Kw/",
  LABORATORIES_URL: "https://medicallaboratories.co.uk/",
  HOME: "https://medicallogistics.co.uk/",
  CONTACT_US: "https://medicallogistics.co.uk/#contact",
  MEDICAL_COURIERS: "https://bookcourier.medicallogistics.co.uk/",
  ABOUT_US: "https://medicallogistics.co.uk/about-us",
  PRESS_ABOUT_US: "https://medicallogistics.co.uk/press-about-us",
  MEDICAL_SERVICES: "https://medicalservices.medicallogistics.co.uk/",
  PRIVACY: "https://medicallogistics.co.uk/privacy-and-cookies-policy",
  TERMS: "https://medicallogistics.co.uk/terms-and-conditions",
  MODERN_SLAVERY: "https://medicallogistics.co.uk/modern-slavery-policy",
  BLOG: "https://blog.medicallogistics.co.uk/",
};
