import clsx from "clsx";
import React, { FunctionComponent } from "react";
import { NavbarVariant } from "../Navbar/NavbarVariant";

type NavbarSpaceProps = {
  variant?: NavbarVariant;
};

const NavbarSpace: FunctionComponent<NavbarSpaceProps> = ({ variant }) => {
  return (
    <div
      className={clsx(
        "lc-h-[3.75rem] lc-w-full",
        "sm:lc-h-16",
        "xl:lc-h-[4.5rem]",
        "2xl:lc-h-20",
        variant === "light" && "lc-bg-main-warm",
        variant === "dark" && "lc-bg-main-dark",
        variant === "mint" && "lc-bg-mint-medical",
        !variant && "lc-bg-transparent"
      )}
    />
  );
};

export default NavbarSpace;
